import {BooleanNumField} from '@/components/field'
import {Product} from '@/types'
import * as React from 'react'
import {ReactNode, useCallback} from 'react'
import {
	Datagrid,
	DatagridProps,
	DateField,
	EditButton,
	FunctionField,
	Link,
	TextField,
	useCreatePath
} from 'react-admin'

const DatagridActionsColumn = ({label, children}: {
	label: string | ReactNode,
	children: ReactNode
}) => <>{children}</>

export default function ProductsDatagrid(props: DatagridProps) {

	const createPath = useCreatePath()
	const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => e.stopPropagation(), [])

	return (
		<Datagrid
			optimized
			rowClick="edit"
			{...props}
		>
			<TextField source="id"/>
			<TextField source="article"/>
			<FunctionField
				source="name"
				label="Libellé"
				render={(record: Product) =>
					<Link
						to={createPath({resource: 'products', type: 'edit', id: record.id})}
						onClick={handleClick}
						style={{textDecoration: 'none'}}
					>
						<TextField source="name" color="primary"/>
						<TextField source="name_alt" color="black" sx={{
							display: 'block',
							fontStyle: 'italic',
							color: 'black !important',
							fontSize: '12px'
						}}/>
					</Link>
				}
			/>
			{/*<ReferenceField source="id" reference="products" link="edit" label="Libellé">*/}
			{/*    <TextField source="name" />*/}
			{/*    <TextField source="name_alt" color="black" sx={{ display: 'block', fontStyle: 'italic', color: 'black !important', fontSize: '12px'}} />*/}
			{/*</ReferenceField>*/}
			<TextField source="typeName" label="Type"/>
			<TextField source="categoryName" label="Catégorie"/>
			<DateField source="updated_at"/>
			<TextField source="highestTrustValue" label="Indice de confiance max"/>
			<FunctionField
				source="is_published"
				label="Publié"
				render={(record: any, source: any) =>
					<BooleanNumField
						label="Publié"
						source={source}
					/>
				}
			/>
			{props.children}
			<DatagridActionsColumn
				label=""//{<LinkUserDialog/>}
			>
				<EditButton/>
			</DatagridActionsColumn>
		</Datagrid>
	)
}
