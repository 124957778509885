import { useEffect, useState } from 'react'
import { Card, CardContent } from '@mui/material'
import { FilterListItem, FilterLiveSearch } from 'react-admin'
import { FilterList } from '@/components/filter/FilterList'
import CategoryIcon from '@mui/icons-material/Category'

const Aside = () => {
    const VIEW_FILTERS_FALSE = {
        filterCategory: false,
    }
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true);
    const [viewFilters, setViewFilters] = useState<PropsViewFiltersMedia>(VIEW_FILTERS_FALSE)

    interface PropsViewFiltersMedia {
        filterCategory: boolean,
    }

    // ###################
    // CHARGEMENT DES DONNEES 
    useEffect(() => {
        const getOptions = () => {
            /**
             * Categories
             */

            fetch(process.env.REACT_APP_DB_LOPT + '/media/categories?_end=null')
                .then(res => res.json())
                .then((res) => {
                    setCategories(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
                })

            setLoading(false)
        }

        if (loading) {
            getOptions()
        }
    }, [])

    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />

                <FilterList
                    active={viewFilters.filterCategory}
                    label="resources.medias.filters.category"
                    icon={<CategoryIcon />}
                    onClick={() =>
                        setViewFilters({
                            ...viewFilters,
                            filterCategory: !viewFilters.filterCategory
                        })
                    }
                >
                    <FilterListItem
                        key="categorie0"
                        label="resources.medias.filters.undefined"
                        value={{ category: 0 }}
                    />

                    {categories && categories.map((category: any) => (
                        <FilterListItem
                            key={"category" + category.id}
                            label={String(category.name)}
                            value={{ category: category.id }}
                        />
                    ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;