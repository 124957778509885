import {defaultTheme} from 'react-admin'

export const customTheme = {
	...defaultTheme,
	palette: {
		primary: {
			main: '#3a4e38'

		},
		secondary: {
			light: '#80a07d',
			main: '#80a07d',
			dark: '#3a4e38',
			contrastText: '#fff'
		},
		background: {
			default: '#fcfcfe'
		},
		mode: 'light' as 'light'
	},
	link: {
		'&:hover': {
			color: 'red' // Change 'red' to your desired color
		}
	},
	shape: {
		borderRadius: 5
	},
	components: {
		...defaultTheme.components,
		MuiTypography: {
			styleOverrides: {
				h5: {
					fontWeight: 500
				},
				h6: {
					fontWeight: 500
				},
				overline: {
					fontWeight: 600
				}
			}
		},
		RaMenuItemLink: {
			styleOverrides: {
				root: {
					borderLeft: '2px solid #fff',
					// '&::before': {
					//     content: '',
					//     position: 'absolute',
					//     display: 'block',
					//     width: 3,
					//     height: '100%',
					//     inset: '3px auto 3px 5px',
					// },
					'&.RaMenuItemLink-active': {
						borderLeftColor: '#80a07d',
						'.RaMenuItemLink-icon': {
							color: '#80a07d'
						}
					}
					// '&.RaMenuItemLink-active::before': {
					//     backgroundColor: '#80a07d'
					// }
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined' as const
			},
			styleOverrides: {
				root: {
					marginTop: 8,
					marginBottom: 4
				}
			}
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'outlined' as const
			}
		},
		MuiAlert: {
			styleOverrides: {
				elevation1: {
					boxShadow: 'none'
				},
				root: {
					border: '1px solid #e0e0e3',
					backgroundClip: 'padding-box'
				}
			}
		},
		MuiCard: {
			styleOverrides: {
				elevation1: {},
				root: {
					// boxShadow: 'none',
					// border: '1px solid #e0e0e3'
					// backgroundClip: 'padding-box'
				}
			}
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					padding: '10px 8px',
					boxShadow: 'none',
					borderBottom: '1px solid #dfd6c8'
				},
				colorSecondary: {
					color: '#1c1f2a',
					backgroundColor: '#f6f3ee'
				}
			}
		}
	}
}
//
// export const defaultDarkTheme = {
//     palette: {
//         primary: {
//             main: '#d8ddc1'
//         },
//         secondary: {
//             main: '#d8ddc1'
//         },
//         background: {
//             default: '#202020'
//         },
//         mode: 'dark' as 'dark' // Switching the dark mode on is a single property value change.
//     },
//     sidebar: {
//         width: 200
//     },
//     components: {
//         ...defaultTheme.components,
//         RaMenuItemLink: {
//             styleOverrides: {
//                 root: {
//                     borderLeft: '3px solid #3a4e38',
//                     '&.RaMenuItemLink-active': {
//                         borderLeft: '3px solid #3a4e38'
//                     }
//                 }
//             }
//         },
//         MuiAppBar: {
//             styleOverrides: {
//                 colorSecondary: {
//                     color: '#808080',
//                     backgroundColor: '#fff'
//                 }
//             }
//         }
//     }
// }
//
// export const defaultLightTheme = {
//     palette: {
//         primary: {
//             main: '#4f3cc9'
//         },
//         secondary: {
//             light: '#5f5fc4',
//             main: '#283593',
//             dark: '#001064',
//             contrastText: '#fff'
//         },
//         background: {
//             default: '#fcfcfe'
//         },
//         mode: 'light' as 'light'
//     },
//     shape: {
//         borderRadius: 10
//     },
//     sidebar: {
//         width: 200
//     },
//     // props: {
//     //     MuiTextField: {
//     //         variant: 'outlined',
//     //         size: 'medium'
//     //     },
//     //     MuiFormControl: {
//     //         variant: 'outlined',
//     //         size: 'medium'
//     //     }
//     // },
//     components: {
//         ...defaultTheme.components,
//         // MuiInput: {
//         //     defaultProps: {
//         //         variant: 'outlined' as const,
//         //         size: null
//         //     }
//         // },
//         RaMenuItemLink: {
//             styleOverrides: {
//                 root: {
//                     borderLeft: '2px solid #fff',
//                     '&.RaMenuItemLink-active': {
//                         borderLeft: '2px solid #4f3cc9'
//                     }
//                 }
//             }
//         },
//         // MuiInputBase: {
//         //     defaultProps: {
//         //         variant: 'outlined' as const,
//         //         margin: 'dense' as const,
//         //         size: 'medium' as const
//         //     }
//         // },
//         MuiInput: {
//             // defaultProps: {
//             //     variant: 'outlined' as const,
//             //     margin: 'dense' as const
//             //     // size: 'normal' as const
//             // }
//         },
//         MuiTextField: {
//             defaultProps: {
//                 variant: 'outlined' as const
//             },
//             styleOverrides: {
//                 root: {
//                     marginTop: 8,
//                     marginBottom: 4
//                 }
//             }
//         },
//         MuiFormControl: {
//             defaultProps: {
//                 variant: 'outlined' as const
//             }
//         },
//         MuiPaper: {
//             styleOverrides: {
//                 elevation1: {
//                     boxShadow: 'none'
//                 },
//                 root: {
//                     border: '1px solid #e0e0e3',
//                     backgroundClip: 'padding-box'
//                 }
//             }
//         },
//         MuiAppBar: {
//             styleOverrides: {
//                 colorSecondary: {
//                     color: '#808080',
//                     backgroundColor: '#fff'
//                 }
//             }
//         },
//         MuiLinearProgress: {
//             styleOverrides: {
//                 colorPrimary: {
//                     backgroundColor: '#f5f5f5'
//                 },
//                 barColorPrimary: {
//                     backgroundColor: '#d7d7d7'
//                 }
//             }
//         }
//     }
// }
