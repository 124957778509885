import FullNameField from '@/components/field/FullNameField'
import DefaultEditor from '@/components/form/DefaultEditor'
import PreviewImage from '@/layout/PreviewImage'
import ProductCommunes from '@/products/ProductCommunes'
import ProductTerroirs from '@/products/ProductTerroirs'
import TagCreate from '@/tags/ModalTagCreate'
import LaunchIcon from '@mui/icons-material/Launch'
import {Box, Grid, Link, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {
    AutocompleteArrayInput,
    CheckboxGroupInput,
    DeleteButton,
    Edit,
    ReferenceArrayInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useEditController,
    useNotify,
    useTranslate
} from 'react-admin'
import './style.css'

export default function ProductEdit() {

    const translate = useTranslate()
    const {record, isLoading: loading} = useEditController()

    const [optionsT, setOptionsT] = useState<any>([])
    const [availableCategories, setAvailableCategories] = useState<any>([])

    useEffect(() => {
        if (record) {
            document.title = record.name
        }
    }, [record])

    useEffect(() => {

        const getOptions = async () => {

            /**
             * Types
             */
            fetch(process.env.REACT_APP_DB_LOPT + '/products/types')
                .then(res => res.json())
                .then((res) => {
                    // console.log(res)
                    setOptionsT(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
                })
            /**
             * Categories
             */
            fetch(process.env.REACT_APP_DB_LOPT + '/products/categories?_end=null')
                .then(res => res.json())
                .then((res) => {
                    // console.log(res)
                    setAvailableCategories(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
                })
        }

        getOptions()

    }, [])

    return (
        <Edit mutationMode="optimistic" title={<ProductTitle/>}>
            <SimpleForm
                record={record}
                warnWhenUnsavedChanges
                noValidate
                toolbar={<FormToolbar/>}
                sx={{
                    padding: {
                        md: '32px',
                        xs: '16px'
                    }
                }}
            >
                <Grid container display={{xs: 'block', md: 'flex'}} sx={{gap: '30px', justifyContent: 'space-between'}}>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.products.fieldGroups.identity')}
                        </Typography>
                        <Box sx={{display: 'flex', gap: '20px'}}>
                            <TextInput
                                disabled
                                source="id"
                                id="identifiant"
                                required
                                fullWidth
                            />
                            <SelectInput
                                isLoading={loading}
                                className="selectInput"
                                source="is_published"
                                format={v => String(v)}
                                optionValue="id"
                                optionText="LIBELLE"
                                label="Publié ?"
                                choices={[
                                    {id: false, LIBELLE: 'Non'},
                                    {id: true, LIBELLE: 'Oui'}
                                ]}
                                emptyValue="empty"
                                fullWidth
                                required
                                validate={req}
                            />
                        </Box>

                        <TextInput
                            source="name"
                            fullWidth
                            validate={req}
                        />

                        <TextInput
                            source="name_alt"
                            fullWidth
                        />

                        <TextInput
                            source="article"
                            fullWidth
                        />

                        <SelectInput
                            className="selectInput"
                            choices={optionsT}
                            isLoading={loading}
                            source="type"
                            optionText="name"
                            optionValue="id"
                            emptyValue="empty"
                            label="Type"
                            fullWidth
                            validate={req}
                        />

                        <SelectInput
                            className="selectInput"
                            choices={availableCategories}
                            isLoading={loading}
                            source="category"
                            optionText="name"
                            optionValue="id"
                            emptyValue="empty"
                            label="Catégorie"
                            fullWidth
                            validate={req}
                        />

                        <SelectInput
                            className="selectInput"
                            choices={[
                                {id: false, label: 'Non'},
                                {id: true, label: 'Oui'}
                            ]}
                            isLoading={loading}
                            source="is_registered_trademark"
                            optionText="label"
                            optionValue="id"
                            emptyValue="empty"
                            helperText={false}
                            label="Marque déposée"
                            fullWidth
                        />

                        <CheckboxGroupInput
                            label="Label(s) de qualité"
                            source="labels"
                            helperText={false}
                            choices={[
                                {id: 'is_aoc', name: 'AOC'},
                                {id: 'is_igp', name: 'IGP'}
                            ]}
                        />

                        <ReferenceArrayInput
                            name="tags"
                            label="Tags"
                            reference="tags"
                            source="tags"
                        >
                            <AutocompleteArrayInput
                                blurOnSelect={false}
                                name="tags"
                                optionText="name"
                                multiple={true}
                                optionValue="id"
                                debounce={250}
                                create={<TagCreate defaultTypeName="Produit"/>}
                            />
                        </ReferenceArrayInput>

                        <Box sx={{display: 'flex'}}>
                            <TextInput
                                type="text"
                                source="link_external"
                                fullWidth
                            />
                            {record && record.link_external &&
                                <Link
                                    sx={{
                                        alignSelf: 'center',
                                        padding: '0 15px',
                                        height: '40px',
                                        pointerEvents: record.link_external ? 'inherit' : 'none',
                                        cursor: record.link_external ? 'pointer' : 'not-allowed'
                                    }}
                                    target="_blank"
                                    href={record.link_external}
                                >
                                    <LaunchIcon color={record.link_external ? 'primary' : 'disabled'}/>
                                </Link>
                            }
                        </Box>

                        <TextInput
                            source="sources"
                            fullWidth
                        />

                        <TextInput
                            source="created_at"
                            disabled={true}
                            fullWidth
                        />

                        <TextInput
                            source="updated_at"
                            disabled={true}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.products.fieldGroups.identitevisuelle'
                                )}
                            </Typography>
                            <PreviewImage
                                source="image"
                                resource="produit"
                            />
                        </Grid>
                        <Box mt="4em"/>
                        {record && record.id && <ProductTerroirs productId={record.id}/>}
                        <Box mt="4em"/>
                        {record && record.id && <ProductCommunes productId={record.id}/>}
                    </Grid>
                </Grid>
                <Box mt="3em"/>
                <DefaultEditor/>
            </SimpleForm>
        </Edit>
    )
}

const req = [required()]

const ProductTitle = () => <FullNameField size="32" sx={{margin: '5px 0'}}/>

const FormToolbar = () => {

    const notify = useNotify()

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton alwaysEnable className="save" label="Enregistrer et quitter"/>
                <SaveButton alwaysEnable className="save" label="Enregistrer" type="button" variant="text"
                            mutationOptions={{
                                onSuccess: (data) => {
                                    notify('ra.notification.updated', {
                                        type: 'info',
                                        messageArgs: {smart_count: 1}
                                    })
                                }
                            }}/>
            </Box>
            <DeleteButton/>
        </Toolbar>
    )
}
