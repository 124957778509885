import { ReactNode, useEffect, useState } from 'react';
import { Box, BoxProps, List, Typography } from '@mui/material';
import { useTranslate } from 'ra-core';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { transitions } from '@/products/Aside';

export const FilterList = (props: FilterListProps) => {
    const { 
        label,
        icon, 
        active = false,
        children = null, 
        toggle = true, 
        onClick, 
        ...rest } = props;
    const translate = useTranslate();

    return (
        <Box {...rest} sx={styles.filter}>
            <Box mt={2} display="flex" alignItems="center" onClick={onClick}>
                <Box display="flex">
                    <Box mr={1}>{icon}</Box>
                    <Typography variant="overline">
                        {translate(label, { _: label })}
                    </Typography>
                </Box>
                { toggle && (active ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>) }
            </Box>
            { toggle && 
                <List dense disablePadding sx={transitions(active, '0.4s')}>
                    {children}
                </List>
            }
        </Box>
    );
};

export interface FilterListProps extends BoxProps {
    label: string;
    icon: ReactNode;
    active: boolean;
    toggle?: boolean;
}

export const styles = {
    filter: {
        '>div:first-child': {
            justifyContent: 'space-between',
            padding: '0 10px',
            '&:hover': {             
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '6px',
                color: '#4f3cc9'
            }
        },
        'ul > li': {
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '6px',
                color: '#4f3cc9'
            }
        }
    },
}
