import {useState} from 'react'
import { Card, CardContent } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {
    FilterLiveSearch,
    useListFilterContext,
} from 'react-admin';
import FilterTerroirs from '@/components/filter/FilterTerroirs';
import { FilterList } from '@/components/filter/FilterList';

const Aside = () => {
    const VIEW_FILTERS_FALSE = {
        filterTerroirs: false        
    }
    const [viewFilters, setViewFilters] = useState<PropsViewFiltersContribution>(VIEW_FILTERS_FALSE)
    const { filterValues, setFilters } = useListFilterContext();

    interface PropsViewFiltersContribution {
        filterTerroirs: boolean,
    }

    const resetFilters = () => {
        setViewFilters(VIEW_FILTERS_FALSE)
        setFilters([], false)
    }

    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 8,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />

                <FilterTerroirs 
                    active={viewFilters.filterTerroirs}
                    onClick={()=>
                        setViewFilters({
                            ...viewFilters, 
                            filterTerroirs: !viewFilters.filterTerroirs
                        })
                    }
                />

                <FilterList
                    label="Réinitialiser les filtres"
                    icon={<ClearIcon />}
                    active={true}
                    toggle={false}
                    sx={{...styles.filter, '>div span' : {fontSize: '10px'}}}
                    onClick={resetFilters}
                />
            </CardContent>
        </Card>
    );
};

export default Aside;

export const styles = {
    filter: {
        '>div:first-child': {
            justifyContent: 'space-between',
            padding: '0 10px',
            '&:hover': {             
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '6px',
                color: '#4f3cc9'
            }
        },
        'ul > li': {
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '6px',
                color: '#4f3cc9'
            }
        }
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        defaultBtn: {
            height: '36px',
        },
        btn1: {
            width: '70%'
        },
        btn2: {
            width: '30%'
        }
    }
}

export const transitions = (active: boolean = false, delay: string) => {
    return (
        {
            overflow: 'hidden', 
            maxHeight: active ? '1000px' : 0,
            transition: 'max-height ' + delay + ' ease-in-out'           
        }
    )
}
